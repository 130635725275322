<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getFmsMetaPage"
      :deleteFun="deleteFmsMetaInfo"
      :createFun="createFmsMetaInfo"
      :updateFun="updateFmsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getFmsMetaPage, createFmsMetaInfo, updateFmsMetaInfo, deleteFmsMetaInfo } from '@/api/fms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        { label: '税率代码', name: 'tax_code', fieldType: '' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '税率代码',
          dataIndex: 'tax_code'
        },
        {
          title: '税率描述',
          dataIndex: 'tax_desc'
        },
        {
          title: '税率',
          dataIndex: 'rate',
          customRender: text => {
            return text !== 0 ? text * 100 + '%' : 0 + '%'
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        tax_code: [
          { required: true, message: '请填写税率代码', trigger: 'blur' }
        ],
        tax_desc: [
          { required: true, message: '请填写税率描述', trigger: 'blur' }
        ],
        rate: [
          { required: true, message: '请填写税率', trigger: 'blur' }
        ]
      },
      modelTitle: '税率管理',
      formFields: [
        {
          label: '税率代码', name: 'tax_code', type: 'default'
        },
        {
          label: '税率描述', name: 'tax_desc', type: 'default'
        },
        {
          label: '税率', name: 'rate', type: 'number'
        }
      ],
      moduleName: 'tax_rate'
    }
  },
  methods: {
    getFmsMetaPage,
    createFmsMetaInfo,
    updateFmsMetaInfo,
    deleteFmsMetaInfo
  }
}
</script>
